/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:25c3bdc4-9f1f-4b06-aa55-76509053292f",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_62au8iLmO",
    "aws_user_pools_web_client_id": "i78niab18re3ql63lgomu0m3g",
    "oauth": {},
    "aws_cognito_login_mechanism": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_appsync_graphqlEndpoint": "https://phutqezyeveadhgey6wcfqmify.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-eppjr4qe4fasfbn7g2zq4it2vi",
    "aws_user_files_s3_bucket": "artcollect20cb43af5da74a468db5a2a782fdf114172111-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
